<template>
  <div>
    <b-form-group label="Interact Categories">
      <multiselect
        label="name"
        v-model="rowData.inter_categories"
        :options="interCategories"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        track-by="name"
        :maxHeight="200"
        @select="selectInterCategory"
        @remove="removeInterCategory"
      ></multiselect>
    </b-form-group>
    
    <b-form-group label="Video Categories">
      <multiselect
        label="name"
        v-model="rowData.categories"
        :options="categories"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        track-by="name"
        :maxHeight="200"
        @select="selectCategory"
        @remove="removeCategory"
      ></multiselect>
    </b-form-group>
  </div>
</template>
<script>
  const Multiselect = () => import('vue-multiselect');
  import { mapGetters } from 'vuex';

  export default {
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
      rowIndex : {
        type : Number,
      },
    },
    data() {
      return {
        value : null,
      };
    },
    computed : {
      ...mapGetters({
        categories      : 'categories/categories',
        interCategories : 'categories/interactCategories',
      }),
    },
    components : {
      Multiselect,
    },
    methods : {

      /**
       * Select Category
       * @param value
       */
      selectCategory(value) {
        this.$http.post('api/categoryvideo', {
          mediaId    : this.rowData.media_id,
          categoryId : value.id,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Added ' + this.rowData.media_title + ' to ' + value.name,
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Remove Category
       * @param value
       */
      removeCategory(value) {
        this.$http.delete('api/categoryvideo', {
          data : {
            mediaId    : this.rowData.media_id,
            categoryId : value.id,
          },
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Removed ' + this.rowData.media_title + ' from ' +
              value.name,
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Select Interact Category
       * @param value
       */
      selectInterCategory(value) {
        this.$http.post('api/interact/category/video', {
          interCategoryId : value.id,
          mediaId         : this.rowData.media_id,
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Added ' + this.rowData.media_title +
              ' to Interact Category - ' + value.name,
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },

      /**
       * Remove Interact Category
       * @param value
       */
      removeInterCategory(value) {
        this.$http.delete('api/interact/category/video', {
          data : {
            interCategoryId : value.id,
            mediaId         : this.rowData.media_id,
          },
        }).then(() => {
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success',
            text  : 'Removed ' + this.rowData.media_title +
              ' from Interact Category - ' + value.name,
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'failed',
            title : 'Failed',
            text  : 'Oops! Something went wrong!',
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../../assets/scss/components/admin/media/media-category-select';
</style>
